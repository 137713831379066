import react, {useRef} from "react";

const Input = ({ label = "", value = "", className = "form-control form-input", readonly = false, errors = [], ...props }) => {
    let randForId = "field-"+Math.random(0,9999);
    const editorRef = useRef(null);

    const hasError = !errors[props.name] ? "" : "is-invalid"
    const ErrorDialog = () => {
        return !errors[props.name] ? <></> :
         (
           <div className="invalid-feedback">
               {errors[props.name][0]}
           </div>
        )
    }


    const handlePaste = (e) => {
        e.preventDefault();
        const text = (e.originalEvent || e.clipboardData).getData('text/plain');
        e.target.value = text;
      };

      

    return (
        <div>
            <label htmlFor={randForId} className="form-label">{label}</label>
            <input ref={editorRef} onPaste={handlePaste} value={value === null ? '' : value} id={randForId} className={`${className} ${hasError}`} readOnly={readonly} {...props} />
            <ErrorDialog />
        </div>
    )
}

export default Input;