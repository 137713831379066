import axios from 'axios';
//import Config from '../../public/config';
//import { useNavigate, Link } from "react-router-dom";

function Request(url, method, params = {}, withCredentials = false, callback) {
    //const navigate = useNavigate();
    const prettifySuccess = (response) => {
        if(response.status === 200){
            return response.data;
        }else if(response.data && response.data.code && response.data.code == 401){
            //navigate(`/`);
        }else{
            console.log(response);
            return response.data
        }
    }

    const req = async () => {
        let token = localStorage.getItem("token-api");
        //const baseURL = "https://request.nightspotapp.com/"; 
        const baseURL = process.env.REACT_APP_API_URL;

        let headers = {
            'Content-Type': 'application/json',
            //'Authorization': 'Bearer '+token
        }

        if(withCredentials){
            if(token && token.length > 0){
                headers["Authorization"] = 'Bearer '+token;
            }else{
                return {
                    status: false,
                    erro: 'Is Guest' 
                }
            }
            
            
        }

        var response = null;
        let axiosReq = axios.create({
            baseURL: `${baseURL}`,
            headers: headers
        })
        try {
            let requestObj = {
                method: method,
                url: "/index.php?r="+url,
                responseType: 'json',
                // data: params,
                // params:params,
            };

            switch (method) {
                case 'GET':
                    requestObj["params"] = params;
                    break;
                case 'POST':
                    requestObj["data"] = params;
                    break;
            
                default:
                    break;
            }

            await axiosReq.request(requestObj).then((dataResponse) =>{
                response = dataResponse
            }).catch((error) => {
                if(error.response.status === 401){
                    localStorage.removeItem('token-api');
                }
                

                console.log("Error: "+url)
                console.log(error)
                //alert("Error: "+url)
                addLogErroNetwork(error, url)
            });
        } catch (error) {
            console.log("Error: "+url)
            console.log(error)
        }
        if(response !== null){
            return prettifySuccess(response);
        }else{
            return {
                status: false,
                erro: 'Erro de ligação' 
            }
        }
        
    }

    

    return req();

}


function addLogErroNetwork(errorMsg, url){
    let messageArr = [];
    let storeError = localStorage.getItem('log-error-msg');
    if (typeof storeError !== 'undefined' && storeError !== null) {
        messageArr = JSON.parse(storeError);
    }
    messageArr.push({
        type: 'NetWork Error',
        dataHoraClient: errorLogGetDate(),
        menssagem: errorMsg.response.data.previous.name+" - "+errorMsg.response.data.previous.message,
        url: url,
        lineNumber: "ERRO: "+errorMsg.response.status,
    })
    localStorage.setItem('log-error-msg', JSON.stringify(messageArr));
}

function errorLogGetDate(){
    var date = new Date();
    var dateStr =
      ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
      ("00" + date.getDate()).slice(-2) + "/" +
      date.getFullYear() + " " +
      ("00" + date.getHours()).slice(-2) + ":" +
      ("00" + date.getMinutes()).slice(-2) + ":" +
      ("00" + date.getSeconds()).slice(-2);
      return dateStr;
 }
export default Request;