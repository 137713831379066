import React, { useState, useEffect } from "react";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

const Timer = ({timeLeft = 0}) => {
    const secondsToMinutes = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedTime = `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
        return formattedTime;
    }
    
    return (
        <CountdownCircleTimer
            isPlaying
            duration={timeLeft}
            colors={['#ea2c61']}
            colorsTime={[0, timeLeft, 360]}
        >
            {({ remainingTime }) => secondsToMinutes(remainingTime)}
        </CountdownCircleTimer>
    );
}

export default Timer;
