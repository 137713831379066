import React, { useContext, useState, useEffect } from 'react';
import {
    Link,
    useParams,
    useLocation
} from "react-router-dom";
import { AuthContext } from '../../../Context/AppContext';


const Menu = ({ isAbsolute = true }) => {
    let params = useLocation();

    // const [App, setApp] = useState(AppContext);
    const { userInfo } = useContext(AuthContext);
    const [Page, setPage] = useState({
        mobileMenu: {
            isVisible: false
        },
        Url: '/'
        // screenViewFluid: [

        // ]
    })

    useEffect(() => {
        setPage({
            ...Page,
            Url: params.pathname
        })
    }, [params])
    //console.log(Page.Url, params)


    const HeaderUserMenu = () => {
        if (userInfo && userInfo.firstName) {
            return (
                // <li className="nav-item"><Link to="/my/dashboard" className="nav-link btn-feature">Olá, {userInfo.firstName} {userInfo.lastName}</Link></li>  
                <li className="nav-item"><a href="https://onboard.nightspotapp.com/login" target='_blank' className="nav-link btn-feature">Olá, {userInfo.firstName} {userInfo.lastName}</a></li>
            )
        } else {
            return (
                <>
                    <li className="nav-item"><a href="https://business.nightspotapp.com/" target='_blank' className="btn btn-primary btn-sm me-2 flex-grow-1  fs-5 mt-2"><i className='fa-solid fa-sign-in me-3'></i>Registar estabelecimento GRÁTIS</a></li>
                    {/* <li className="nav-item"><a href="https://business.nightspotapp.com/" target='_blank' className="nav-link btn-feature">BUSINESS AREA</a></li> */}
                </>
            )
        }
    }


    const Header = () => {
        const Nav = () => {
            let innerWidth = window.innerWidth;
            //console.log(window.innerWidth)
            //if (window.innerWidth >= 768) { //768

            return (
                <ul className="nav nav-pills pt-2">


                    <HeaderUserMenu />
                </ul>
            )

        }
        return (
            // <div className={`container-fluid ${Page.Url == '/' ? `container-menu` : ``}`} >
            <div className={`container-fluid border-bottom ${isAbsolute ? `menu-background` : ``}  `} >
                <header className="d-flex flex-wrap justify-content-center pt-1 mb-2 " >
                    <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
                        <img src="/img/logo/logo.svg" alt="Logo" className='logo-header' />
                    </a>

                    {Nav()}
                </header>
            </div>
        )


    }
    return Header()

}

export default Menu;