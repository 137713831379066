import React, {useEffect} from "react";
import SignupRequests from "./SignupRequests";
import {useParams, useNavigate } from "react-router-dom";

const SignupActivation = () => {
    const urlParams = useParams();
    const navigate = useNavigate();

    useEffect(async() => {
        document.title = 'Sign up activation | Nightspot';
        let response = await SignupRequests.activateSignUp({token:urlParams.token});
        if (response && response.status) {
            navigate(`/login`);
        }
    }, [])
    

    return (
        <></>
    );
}

export default SignupActivation;