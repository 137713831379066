import { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import { useNavigate, Link, useParams } from "react-router-dom";
import AuthAppRequests from "./AuthAppRequests";

const AuthApp = () => {
    const [isLoading, setIsLoading] = useState(true);
    const urlParams = useParams();
    const navigate = useNavigate();

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    };

    const checkLogin = async () => {
        const tokenApp = getCookie("app-token")
        let response = await AuthAppRequests.checkLoginWebView({tokenApp: tokenApp, ...urlParams});
        if (response && response.status == true) {
            navigate(response.data);
        }
    }

    useEffect(() => {
        checkLogin();
    }, [])

    return isLoading ? <Loading /> : null;
}

export default AuthApp;