import React from 'react';

function MenuUserImage(props) {
  const { letters, backgroundColor } = props;

  const styles = {
    backgroundColor: backgroundColor,
    color: '#ffffff',
    width: '100px',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '48px',
    fontWeight: 'bold',
    borderRadius: '64px',
  };

  return (
    <div style={styles}>
      {letters}
    </div>
  );
}

export default MenuUserImage;
