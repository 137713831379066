import React, { useState, useEffect } from "react";
import { Input } from '../Layout/Forms/Form';
import { useNavigate, Link } from "react-router-dom";
import './ForgotPassword.css';
import ForgotPasswordRequests from './ForgotPasswordRequests';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from "../../components/Loading";

const ForgotPassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Recover password | Nightspot';
    }, [])


    const [state, setState] = useState({
        form: {
            email: "",
        },
        errors: []
    });
    const handleChangeInputs = (e) => {
        if (!isLoading) {
            setState({
                ...state,
                form: {
                    ...state.form,
                    [e.target.name]: e.target.value
                }
            });
        }
    }

    const handleForgotRequest = async (e) => {
        e.preventDefault(); // previne o comportamento padrão do formulário (recarregando a página)
        setIsLoading(true)
        let params = state.form;
        let response = await ForgotPasswordRequests.sendEmailForgotPassword(params);
        if (response && response.status == true) {
            
           
        }
        setIsLoading(false);
        toast.success("Enviámos-lhe um e-mail com instruções para redefinir a sua palavra-passe.", {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () =>  navigate(`/login`)
        });
        setState({
            ...state,
            form:{
                ...state.form,
                email: ""
            }
        })
        
    }

    return ( isLoading ? <Loading /> :
        <div className="container-fluid">
            <ToastContainer />
            <div className="row">
                <div className="col-md-7 block-form-forgot-password">
                    <div className="row">
                        <div className="col-12" style={{ marginTop: "15px" }}>
                            <img className="mb-4" src="/img/logo/logo-icon.svg" alt="" width="72" height="57" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center" style={{ marginTop: "15px" }}>
                            <h1>Perdeu a sua Password?</h1>
                            <span className="title-description">Não se preocupe, enviamos-lhe um e-mail com instruções</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7  mx-auto">
                            <form onSubmit={handleForgotRequest}>
                                <div className="row">
                                    <div className="col-xs-12">
                                        <Input
                                            label="Email"
                                            name="email"
                                            type="email"
                                            value={state.form.email}
                                            onChange={(e) => handleChangeInputs(e)}
                                            errors={state.errors}
                                        />
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: "30px" }}>
                                    <div className="col-12">
                                        <button type="submit" className="btn btn-primary">
                                            Recuperar password
                                        </button>
                                    </div>

                                </div>
                            </form>


                            <div className="row">
                                <div className="col-12 text-center" style={{ marginTop: "20vh" }}>
                                    <Link to="/login" className="link-nostyle" style={{ fontSize: "0.9em" }}><i className="fa-solid fa-arrow-left"></i> VOLTAR AO LOGIN</Link>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="col-xs-12 col-md-5 block-bg-image-forgot-password">
                    <h2>Don't let a forgotten password hold you back. Reset it now and get back on track!</h2>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;