import React from "react";
import Request from "../../../../api/Requests";

const getInfoUserSettings = (params) => {
    
    return Request('user/info-form', 'GET', params, true);
}

const postUser = (params) => {
    
    return Request('user/update-form', 'POST', params, true);
}



export default {
    getInfoUserSettings,
    postUser
}
