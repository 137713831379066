import React, { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import DeleteAccountRequests from "./DeleteAccountRequests";
import { Input, SelectCountries } from '../Layout/Forms/Form';
import Loading from "../../components/Loading";

const DeleteAccount = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const urlParams = useParams();
    const [msgSuccess, setMsgSucess] = useState(false)

    const [state, setState] = useState({
        form: {
            phone_country: "351",
            phone_number: ""
        },
        countryList: [],
        errors: []
    })

    useEffect(async () => {
        document.title = 'Delete Account | Nightspot';
        setIsLoading(true)
        let response = await DeleteAccountRequests.getInfoDeleteAccount(urlParams);
        if (response && response.status == true) {
            setState({
                ...state,
                countryList: response.data.countryList
            })
        } else {
            setIsLoading(false)
            navigate(`/`);
        }
        setIsLoading(false)
    }, [])

    const seriaizeCountryList = () => {
        if (state.countryList && state.countryList.length > 0)
            return state.countryList.map((item) => {
                return {
                    label: item.name,
                    value: item.phone_country,
                    icon: item.icon
                }
            });
    }


    const handleChangeInputs = (e) => {
        if (!isLoading) {
            setState({
                ...state,
                form: {
                    ...state.form,
                    [e.target.name]: e.target.value
                }
            });
        }
    }

    const handlerSubmitDeleteAccount = async () => {
        setIsLoading(true)
        let response = await DeleteAccountRequests.deleteAccount({ ...urlParams, ...state.form });
        if (response && response.status == true) {
            setMsgSucess(true);
        } else {
            //navigate(`/`);
        }
        setIsLoading(false)
    }


    const AlertSuccess = () => {
        return msgSuccess ? (
            <div className="container mt-5">
                <div className="alert alert-success" role="alert">
                    <h4 className="alert-heading">Sucesso</h4>
                    <p>O seu pedido foi concluido com sucesso, dentro de 48h todos os seu dados iram ser eliminados.</p>
                </div>
            </div>
        )
        : null;
    }


    return (isLoading ? <Loading /> :
        <>
            
            <div className="container" style={{display: msgSuccess ? 'none': 'block'}} >
                <div className="row mt-5">
                    <div className="col-12 text-center">
                        <h3>Formulário de eliminação de conta e dados recolhidos</h3>
                    </div>
                </div>
                <div className="row " >
                    <div className="col-8 d-grid  mx-auto " style={{ backgroundColor: '#192025', padding: '20px', borderRadius: '10px' }}>
                        <span>Confirme o seu número de telefone para eliminar a sua conta na nossa aplicação. Toda a informação armazenada por nós sobre a sua conta também será eliminada.</span>

                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                            <SelectCountries
                                label="&nbsp;"
                                name="phone_country"
                                value={state.form.phone_country}
                                onChange={(e) => handleChangeInputs(e)}
                                options={seriaizeCountryList()}
                                errors={state.errors}

                            />
                            <div className="ms-1" style={{ width: '100%' }}>
                                <Input
                                    label="Nº Telemóvel"
                                    name="phone_number"
                                    type="number"
                                    value={state.form.phone_number}
                                    onChange={(e) => handleChangeInputs(e)}
                                    errors={state.errors}
                                />
                            </div>
                        </div>
                        <div className="row mb-5" style={{ marginTop: '30px' }}>
                            <div className="col-12 d-grid  mx-auto text-center">
                                <button onClick={() => handlerSubmitDeleteAccount()} className="btn btn-danger" >Eliminar todos os meus dados recolhidos</button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <AlertSuccess />
        </>
    )
}

export default DeleteAccount;