import React from "react";

import Menu from "../../Layout/Menu/Menu";
import Footer from "../../Layout/Footer/Footer";

const AboutUs = () => {
    return (
        <>
            <Menu />

            <div className="pt-5"></div>
            <div className="container pt-5" >
                <h1>Sobre nós</h1>
                <br/>
                <p>O Nightspot vem revolucionar e agilizar o acesso à vida noturna nos bares e discotecas.</p>
                <p>Não sabes para onde sair, onde estão os melhores eventos da noite ou como podes entrar no estabelecimento de forma fácil, rápida e com as melhores condições?</p>
                <p>Agora tudo será mais fácil e simples! Com a aplicação Nightspot encontra os spots mais perto de ti, bem como os eventos que irão decorrer perto da tua localização. </p>
                <p>Adere ainda às guest lists dos teus spots preferidos de forma imediata e sem complicações. Sim, é verdade, acabaram-se os formulários chatos e aborrecidos, basta um click e já está, aderiste à guest list que te garante as melhores condições da festa!</p>
                <br/>
                <p>Nightspot</p>
                <p>Where the night begins!</p>

            </div>
            <Footer />
        </>
    )
}

export default AboutUs;