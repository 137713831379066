import { Modal, Button } from 'react-bootstrap';
import { useState, useEffect, useContext } from 'react';
import { Input, TextArea, Select, TextAreaAdvanced, InputImageUploadSimple } from "../../../Layout/Forms/Form";
import { AuthContext } from '../../../../Context/AppContext';
import PromotersFormRequests from './PromotersFormRequests';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PromotersFormModal({ show, handleModalShow, handleModalhide, venuesList }) {
    // const [show, setShow] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({
        form: {
            email: '',
        },
        errors: [],
    });


    const handleSubmitForm = async () => {
        let info = await PromotersFormRequests.postPromoters(state.form);
        if (info && info.status) {
            handleModalhide(false);
            toast.success("O promotor foi registado com sucesso", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else {
            setState({
                ...state,
                errors: info.errors
            })
        }
    }

    const handleChangeInputs = (e) => {
        if (!isLoading) {
            setState({
                ...state,
                form: {
                    ...state.form,
                    [e.target.name]: e.target.value
                }
            });
        }
    }

    const seriaizeVenues = () => {
        if (venuesList && venuesList && venuesList.length > 0)
            return venuesList.map((item) => {
                return {
                    label: item.name,
                    value: item.id
                }
            });
    }

    return (
        // <UserSettingsModalContext.Provider value={{ showModalUserSettings: handleShow, hideModal: handleClose }}>
        <>
            <Modal show={show} onHide={handleModalhide}>
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar Promotor</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <Select
                                label="Spot"
                                name="id_venues"
                                value={state.form.id_venues}
                                onChangeOption={(e) => handleChangeInputs(e)}
                                options={seriaizeVenues()}
                                errors={state.errors}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Input
                                label="Email do Promotor"
                                name="email"
                                value={state.form.email}
                                onChange={(e) => handleChangeInputs(e)}
                                type="text"
                                errors={state.errors}
                            />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="alert alert-warning" role="alert">
                                <i className='fa-solid fa-triangle-exclamation me-3'></i> Atenção este promotor vai ser associado a todos os eventos ativos
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalhide}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={handleSubmitForm}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </>
    );
}

export default PromotersFormModal;