import { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';

const SelectCountries = ({name, options = [], value = "+351", onChange }) => {
  const [selectedOption, setSelectedOption] = useState({
    label: '',
    value: '',
    icon: ''
  });
  const [searchQuery, setSearchQuery] = useState('');

  // useEffect(() => {
  //   if (options.length > 0)
  //     setSelectedOption(options[0])
  // }, [options])


  // const handleOptionClick = (option) => {
  //   setSelectedOption(option);
  // };

  useEffect(() => {


    const filteredOptions = options.filter((option) =>
      option.value.toLowerCase().includes(value.toLowerCase())
    );
    
    if(filteredOptions && filteredOptions.length > 0)
      setSelectedOption(filteredOptions[0])

  }, [value])


  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <label className="form-label" style={{ marginTop: '15px' }}>&nbsp;</label>
      <Dropdown className="country-select">

        <Dropdown.Toggle variant="dark">
          {selectedOption && selectedOption.icon ? (
            <img src={selectedOption.icon} alt={selectedOption.label} className="option-image" />
          ) : (
            <span></span>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu variant='scrollbar'>
          <Dropdown.Header >
            <div className="search-container">
              <input
                type="text"
                className="form-control"
                placeholder="Pesquisar..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </Dropdown.Header>
          {filteredOptions.map((option, index) => (
            <Dropdown.Item key={index} onClick={() => onChange({target: {name: name, value: option.value}})}>
              <img src={option.icon} alt={option.label} className="option-image" />
              <span>{option.label}</span>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );

}

export default SelectCountries;