import react from "react";
import Request from "../../api/Requests";

const sendEmailForgotPassword = (params) => {
    return Request('site/forgot-password-email', 'POST', params);
}

const forgotPasswordValidateToken = (params) => {
    return Request('site/forgot-password-token-validate', 'POST', params);
}

const resetPassword = (params) => {
    return Request('site/forgot-password-reset', 'POST', params);
}


export default {
    sendEmailForgotPassword,
    forgotPasswordValidateToken,
    resetPassword
}