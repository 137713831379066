import { Modal, Button } from 'react-bootstrap';
import { useState, useEffect, useContext } from 'react';
import { Input, TextArea, Select, TextAreaAdvanced, InputImageUploadSimple } from "../../../Layout/Forms/Form";
import { AuthContext } from '../../../../Context/AppContext';
import UserSettingsRequests from './UserSettingsRequests';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UserSettingsModal() {
    const { showModalUserSettings, handleHideModalUserSettings } = useContext(AuthContext);
    const [show, setShow] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({
        form: {
            firstname: '',
            lastname: '',
            phone_country: '',
            phone_number: '',
            email: '',
            password: '',
        },
        errors: []
    });

    useEffect(async () => {
        if (showModalUserSettings) {
            setIsLoading(true);
            let info = await UserSettingsRequests.getInfoUserSettings({});
            if (info && info.status) {
                setState(info.data);
                setIsLoading(false);
                setShow(true);
            }
        }
    }, [showModalUserSettings])


    const handleSubmitForm = async () => {
        let info = await UserSettingsRequests.postUser(state.form);
        if (info) {

            if (info.status) {
                toast.success("O seu perfil foi guardado com sucesso.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                handleHideModalUserSettings();
            } else {
                setState({
                    ...state,
                    errors: info.errors
                })
            }

        }
    }

    const handleChangeInputs = (e) => {
        if (!isLoading) {
            setState({
                ...state,
                form: {
                    ...state.form,
                    [e.target.name]: e.target.value
                }
            });
        }
    }

    return (
        // <UserSettingsModalContext.Provider value={{ showModalUserSettings: handleShow, hideModal: handleClose }}>
        <>
        <ToastContainer />
        <Modal show={showModalUserSettings} onHide={handleHideModalUserSettings}>
            <Modal.Header closeButton>
                <Modal.Title>Definições do seu perfil</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                
                <div className="row">
                    <div className="col-6">
                        <Input
                            label="Primeiro Nome"
                            name="firstname"
                            value={state.form.firstname}
                            onChange={(e) => handleChangeInputs(e)}
                            type="text"
                            errors={state.errors}
                        />
                    </div>
                    <div className="col-6">
                        <Input
                            label="Apelido"
                            name="lastname"
                            value={state.form.lastname}
                            onChange={(e) => handleChangeInputs(e)}
                            type="text"
                            errors={state.errors}
                        />
                    </div>

                </div>
                <div className="row">
                    {/* <div className="col-6"> */}
                    {/* <Input
                            label="phone_country"
                            name="phone_country"
                            value={state.form.phone_country}
                            onChange={(e) => handleChangeInputs(e)}
                            type="text"
                        /> */}
                    {/* <SelectCountries
                            label="&nbsp;"
                            name="phone_country"
                            value={form.phone_country}
                            onChange={(e) => handleChangeInputs(e)}
                            options={seriaizeCountryList()}
                            errors={form.errors}
                        /> */}
                    {/* </div> */}
                    <div className="col-6">
                        <Input
                            label="Telemóvel"
                            name="phone_number"
                            value={state.form.phone_number}
                            onChange={(e) => handleChangeInputs(e)}
                            type="text"
                            errors={state.errors}
                        />
                    </div>

                </div>
                <div className="row">
                    <div className="col-12">
                        <Input
                            label="Email"
                            name="email"
                            value={state.form.email}
                            onChange={(e) => handleChangeInputs(e)}
                            type="text"
                            errors={state.errors}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Input
                            label="Password"
                            name="password"
                            value={state.form.password}
                            onChange={(e) => handleChangeInputs(e)}
                            type="password"
                            errors={state.errors}
                        />
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleHideModalUserSettings}>
                    Fechar
                </Button>
                <Button variant="primary" onClick={handleSubmitForm}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
        </>
        // </UserSettingsModalContext.Provider>
    );
}

export default UserSettingsModal;