import React from "react"
import { Puff } from 'react-loading-icons'

const Loading = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '100%' }}>
                <Puff stroke="#f92056" height="5em" width="5em" />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '100%' }}>Loading...</div>
        </div>


    )
}

export default Loading;



