import React, { useEffect, useState } from "react";
import SignupRequests from "./SignupRequests";
import { useParams, useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";

const SignupAppActivation = () => {

    const urlParams = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({
        status: "",
    })

    useEffect(async () => {
        document.title = 'Sign up activation | Nightspot';
        let response = await SignupRequests.activateSignUp({ token: urlParams.token });
        if (response && response.status) {
            //window.location.replace('instagram://');
            setState({ status: response.status })
        }
    }, [])


    const ContentManager = () => {
        if (state.status == true) {
            return (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 block-bg-image-signup" style={{ justifyContent: "flex-start" }}>
                            <img src="/img/logo/logo.svg" style={{ height: '10vh' }} className="img-fluid" />
                            <h2 className="mt-5 text-center">Registo concluido com sucesso 🥳</h2>
                            <p className="text-center mt-3" style={{ fontSize: '1em' }}>Descubra os próximos eventos nas melhores discotecas na nossa app.</p>

                        </div>
                    </div>
                </div>
            )
        } else if(state.status == false) {
            return (
                <div className="container-fluid">
                    <div className="row ">
                        <div className="col-xs-12 col-sm-12 block-bg-image-signup" style={{ justifyContent: "flex-start" }}>
                            <img src="/img/logo/logo.svg" style={{ height: '10vh' }} className="img-fluid" />
                            <div className="container">
                                <div className="row mt-5" style={{ justifyContent: "flex-start" }}>
                                    <div className="alert alert-danger col-xs-12" role="alert">
                                        <h4 className="alert-heading"><i className="fa-solid fa-triangle-exclamation"></i>&nbsp;Alerta</h4>
                                        <p className="fs-6">Ocorreu um erro no processo de ativação da sua conta. Tente novamente.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }


    return (isLoading ? <Loading /> :
        <ContentManager />
    )
}




export default SignupAppActivation;