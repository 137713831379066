import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GuestlistAccessRequests from "./GuestlistAccessRequests";
import './Guestlist.css'
import SelectCountries from "../../Layout/Forms/SelectCountries";
import Input from "../../Layout/Forms/Input";
import Loading from "../../../components/Loading";
import ReCAPTCHA from 'react-google-recaptcha';
import ModalTablesImage from "./ModalTablesImage"


const GuestListAccessEvent = ({ type }) => {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const urlParams = useParams();
    let captcha;
    const setCaptchaRef = (ref) => {
        if (ref) {
            return captcha = ref;
        }
    };

    const refCaptcha = useRef(null);
    const [state, setState] = useState({
        form: {
            phone_country: '351',
            phone_number: '',
            recaptcha: ''
        },
        errors: [],
        countryList: [],
        event: {}
    });
    const [modalImageTables, setModalImageTables] = useState({
        isVisible: false
    })

    const [isVerified, setIsVerified] = useState(false);
    const handleRecaptchaChange = (token) => {
        setIsVerified(true);
        handleChangeInputs({
            target: {
                name: "recaptcha",
                value: token
            }
        })
    };

    useEffect(async () => {
        let response = await GuestlistAccessRequests.getInfoEvent(urlParams, type);
        if (response && response.status == true) {
            setState(response.data)
        } else {
            //navigate(`/`);
        }
        setIsLoading(false)
    }, [])



    const handleChangeInputs = (e) => {

        if (!isLoading) {
            setState({
                ...state,
                form: {
                    ...state.form,
                    [e.target.name]: e.target.value
                }
            });
        }
    }

    const seriaizeCountryList = () => {
        if (state.countryList && state.countryList.length > 0)
            return state.countryList.map((item) => {
                return {
                    label: item.name,
                    value: item.phone_country,
                    icon: item.icon
                }
            });
    }

    const handlerSubmitGuestlist = async (e) => {
        // setIsLoading(true);
        e.preventDefault();


        // console.log(state.form.phone_number.length == 0);
        // return;
        if (state.form.phone_number.length == 0) {
            toast.error("Preenche o Nº Telemóvel.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });

            setState({
                ...state,
                errors: {
                    phone_number: [
                        'Preenche o Nº Telemóvel'
                    ]
                }
            })
            // setIsLoading(false);
            return false;
        }

        // Chamar o reCAPTCHA para obter o token de verificação
        //const recaptchaValue = refCaptcha.current.getValue();
        const recaptchaValue = await refCaptcha.current.executeAsync();
        console.log(recaptchaValue)
        if (recaptchaValue) {
            submitForm(recaptchaValue);
        } else {
            toast.error("Verifique o reCAPTCHA antes de submeter o formulário.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }


        // if (isVerified && state.form.recaptcha) {
        //     let response = await GuestlistAccessRequests.submitEnterGuestlist({ ...urlParams, ...state.form }, type);
        //     if (response && response.status == true) {
        //         //navigate(`/${type == `venue` ? `v` : `u`}/${urlParams.slug}`);
        //         navigate(`/guestlist-complete`);
        //     } else {
        //         //navigate(`/`);
        //     }
        // } else {
        //     toast.error("Verifique o reCAPTCHA antes de submeter o formulário.", {
        //         position: "top-right",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "dark",
        //     });
        // }
        // setIsLoading(false)
    }

    const submitForm = async (recaptchaValue) => {
        setIsLoading(true);
        const formWithToken = { ...state.form, recaptcha: recaptchaValue }
        let response = await GuestlistAccessRequests.submitEnterGuestlist({ ...urlParams, ...formWithToken }, type);
        if (response && response.status == true) {
            navigate(`/guestlist-complete`);
        } else {
            // handle error
        }
        setIsLoading(false)
    }

    const handleShowInfo = () => {
        setState({
            ...state,
            event: {
                ...state.event,
                infoDisplay: true
            }
        })
    }


    const EventInfo = () => {

        const Info = () => {
            if (state.event.infoDisplay) {
                return (
                    <div className="row p-2" style={{ height: '20vh', overflowY: 'scroll' }}>
                        <div className="col-sm-12" dangerouslySetInnerHTML={{ __html: state.event.description }}>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="row p-2" onClick={() => handleShowInfo()}>
                        <div className="col-sm-12 text-center">
                            + Info&nbsp;<i className="fa-solid fa-chevron-down"></i>
                        </div>
                    </div>
                )
            }

        }

        return (
            <>


                <Info />
                <hr className="grad-line"></hr>
            </>
        )
    }

    const SectionLocation = () => {
        return (

            <div className="col-12 ">
                <h4>Localização</h4>
                <div className="mt-3" style={{ color: '#939393' }}>
                    {state.event.location_street}
                </div>
                <div className="mt-3">
                    <a href={`https://www.google.com/maps/@${state.event.latitude},${state.event.longitude},20z`} className="btn btn-primary" target="_blank"><i className="fa-solid fa-location-dot me-3"></i>Ver Mapa</a>
                </div>
            </div>
        )
    }

    const BtnShareSocial = () => {
        return (
            navigator.share ?
                (
                    // <div className="row ">
                    //     <div className="col align-self-end ">
                    //         <div className="position-relative">
                    //             <button className="btnShareSocial z-4" onClick={() => socialShare()}><i className="fa-solid fa-paper-plane"></i></button>
                    //         </div>
                    //     </div>
                    // </div>

                    <div className="position-relative">
                        <button className="btnShareSocial z-4" onClick={() => socialShare()}><i className="fa-solid fa-paper-plane me-2"></i>Partilhar</button>
                    </div>

                )
                : null
        )
    }

    const socialShare = () => {
        if (navigator.share) {
            navigator.share({
                title: `Nightspot | Guest list ${state.event.venue_name}`,
                text: `${state.event.venue_name} - ${state.event.name}`,
                url: window.location.href
            })
                .then(() => console.log('Shared successfully'))
                .catch(error => console.log('Error sharing:', error));
        } else {
            //alert("Native sharing is not supported");
        }
    }

    const TablesList = () => {

        const urlPromoterTable = `/${type == `venue` ? `v` : `u`}/${urlParams.slug}/${state.event.slug}`

        const SoldOut = ({ visibility }) => {
            return !visibility ? '' :
                (
                    <div className="badge-overlay">
                        <span className="badge-diagonal">Esgotado</span>
                    </div>
                )
        }

        const Hold = ({ visibility }) => {
            return !visibility ? '' :
                (
                    <div className="row my-4">
                        <div className="col-12 col-sm-6">
                            <div className="badge bg-warning text-dark" style={{ whiteSpace: 'normal' }}>
                                Em processo de reserva, esta mesa poderá voltar a ficar disponivel dentro de 5 minutos.
                            </div>
                        </div>
                    </div>
                )
        }

        const PriceBox = ({ visibility, price }) => {
            return !visibility ? '' :
                (
                    <div className="table-price">
                        {price}€
                    </div>
                )
        }

        return (
            state.tables.map((item, index) => {
                let linkTable = `${urlPromoterTable}/tables/${item.id}`
                if (item.status !== "PENDING") {
                    linkTable = "#"
                }
                return (
                    <Link key={index} className={`link-nostyle ${item.status !== "PENDING" ? `table-reserved` : ``}`} to={linkTable}>
                        <div className="row mt-4 mt-sm-3" >
                            <div className="col-12">
                                <div className="box-single-table containel-badge" style={{ borderColor: item.color }}>
                                    <SoldOut visibility={item.status == "RESERVED"} />
                                    <div className={`d-flex `}>
                                        <div className="flex-grow-1">
                                            <div className={`table-title ${item.status !== "PENDING" ? `opacity-50` : ``}`} >Mesa {item.name}</div>
                                            <Hold visibility={item.status == "HOLD"} />
                                            <div className={`table-description ${item.status !== "PENDING" ? `opacity-50` : ``}`} >
                                                {item.description}
                                            </div>
                                        </div>
                                        <div className={`align-self-center ${item.status !== "PENDING" ? `opacity-50` : ``}`}>
                                            <PriceBox price={item.base_price} visibility={item.status !== "RESERVED"} />
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </Link>
                )
            })

        )
    }

    const FloorImage = () => {
        return (state.tabled_img_url) ?
            (
                <>
                    <button className="btn btn-primary" type="button" onClick={() => setModalImageTables({
                        ...ModalTablesImage,
                        isVisible: true
                    })}><i className="fa-solid fa-image me-3"></i>Ver planta das mesas</button>

                </>
            ) : null
    }

    return (isLoading ? <Loading /> :
        <div className="container-fluid">
            <div className="position-absolute opacity-25 dark:opacity-25" style={{ minHeight: '100vh', background: `url('${state.event.image}') center center / cover`, left: '-30%', top: '-30%', width: '100%', filter: 'blur(100px)' }}>
            </div>
            <ToastContainer />
            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12" style={{ marginTop: "15px", textAlign: 'center' }}>
                            <img className="mb-4" src="/img/logo/logo.svg" alt="" style={{ height: '10vh' }} />
                        </div>
                    </div>
                    <div className="container-fluid container-sm p-0">
                        <div className="row g-0">
                            <div className="col-12 block-guestlist-enter">
                                <div className="row p-4 p-sm-5">
                                    <div className="col-12 col-sm-4">


                                        <div className="row">
                                            <div className="col-12 ">
                                                <img src={state.event.image} className="img-fluid img-event-single" />
                                            </div>
                                        </div>
                                        <div className="row mt-3 d-none d-sm-block">
                                            <SectionLocation />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-8">
                                        <div className="row mt-3 mt-sm-0 ">
                                            <div className="col-12">
                                                {state.event.date_formated}
                                                <small className="mx-3" style={{ color: 'rgb(234, 44, 97)' }}>|</small>
                                                <span style={{ color: '#939393' }}>{state.event.hour_start} - {state.event.hour_end}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 mt-3">
                                                <div className="row ">
                                                    <div className="col align-self-start ">
                                                        <div style={{ fontSize: '2rem', fontWeight: 'bold' }}>{state.event.name}</div>
                                                    </div>
                                                    <div className="col align-self-end text-end">
                                                        <BtnShareSocial />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 mt-3">
                                                {state.event.description}
                                            </div>
                                        </div>
                                        {state.event.guestlist_enabled ?
                                            (

                                                <div className="row mt-5">
                                                    <div className="col-12">
                                                        <h4><small className="me-3" style={{ color: 'rgb(234, 44, 97)' }}>|</small>GUEST LIST</h4>

                                                        {!state.event.blockRegistry ? (
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <form onSubmit={handlerSubmitGuestlist} className="p-3 position-relative z-2" style={{ backgroundColor: 'rgba(0, 0, 0, .3)' }}>
                                                                        <div className="row">
                                                                            <div className="col-12 d-grid  mx-auto ">
                                                                                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                                                                    <SelectCountries
                                                                                        label="&nbsp;"
                                                                                        name="phone_country"
                                                                                        value={state.form.phone_country}
                                                                                        onChange={(e) => handleChangeInputs(e)}
                                                                                        options={seriaizeCountryList()}
                                                                                        errors={state.errors}

                                                                                    />
                                                                                    <div className="ms-1">
                                                                                        <Input
                                                                                            label="Nº Telemóvel"
                                                                                            name="phone_number"
                                                                                            type="number"
                                                                                            value={state.form.phone_number}
                                                                                            onPaste={(e) => { e.preventDefault(); handleChangeInputs({ target: { name: e.target.name, value: e.clipboardData.getData('Text') } }) }}
                                                                                            onChange={(e) => handleChangeInputs(e)}
                                                                                            errors={state.errors}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row" style={{ marginTop: '30px' }}>
                                                                            <div className="col-12">
                                                                                <ReCAPTCHA size="invisible" ref={refCaptcha} theme="dark" sitekey="6LeJQrwpAAAAAHSzvB8Z_eA8SBZk9YBOBwBU6qyz" onChange={handleRecaptchaChange} />

                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-5" style={{ marginTop: '30px' }}>
                                                                            <div className="col-8 d-grid  mx-auto text-center">
                                                                                <button type="submit" className="btn btn-primary" >Entrar na guestlist</button>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="row">
                                                                <div className="col-12" >
                                                                    <div className="p-3 position-relative z-2" style={{ backgroundColor: 'rgba(0, 0, 0, .3)' }}>
                                                                        <div className="row p-3">
                                                                            <div className="col-12 col-sm-6 alert alert-danger">
                                                                                <i className="fa-solid fa-times me-3 fs-3"></i>Guest list esgotada.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                            ) : null}
                                        {state.event.tables_enabled ? (
                                            <div className="row mt-5">
                                                <div className="col-12">
                                                    <div className="position-relative z-2">
                                                        <h4><small className="me-3" style={{ color: 'rgb(234, 44, 97)' }}>|</small>MESAS</h4>
                                                        <div className="box-tables ">

                                                            <FloorImage />
                                                            <TablesList />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}


                                        <div className="row mt-3 d-block d-sm-none">
                                            <SectionLocation />
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalTablesImage
                show={modalImageTables.isVisible}
                handleModalhide={() => setModalImageTables({
                    ...modalImageTables,
                    isVisible: false
                })}
                info={state.tabled_img_url}
            />
        </div>

    )
}

export default GuestListAccessEvent;