import React from "react";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { Modal, Button } from 'react-bootstrap';

const ModalTablesImage = ({ show, handleModalhide, info }) => {
    return (
        <>
            <Modal show={show} onHide={handleModalhide}>
                <Modal.Header closeButton>
                    <Modal.Title>Imagem</Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ height: '75vh' }}>
                    <div style={{textAlign: 'center'}}>
                        <Zoom>
                            <img
                                alt="Planta Mesas VIP"
                                src={info}
                                height="400"
                            />
                        </Zoom>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalhide}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalTablesImage;