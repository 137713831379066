import react from "react";
import './Footer.css';
import {
  Link
} from "react-router-dom";

const Footer = () => {
  return (
    <footer className="py-5">
        <div className="container">
                <div className="row">
                    <div className="col-6 col-md-3 mb-3">
                        <h5>Geral</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><Link to="/about" className="nav-link p-0 text-body-secondary">Sobre nós</Link></li>
                            <li className="nav-item mb-2"><a href="/#section-features" className="nav-link p-0 text-body-secondary">Funcionalidades</a></li>
                            <li className="nav-item mb-2"><Link to="/privacy" className="nav-link p-0 text-body-secondary">Termos & Privacidade</Link></li>
                            {/* <li className="nav-item mb-2"><a href="/faqs" className="nav-link p-0 text-body-secondary">FAQs</a></li> */}
                        </ul>
                    </div>

                    <div className="col-6 col-md-3 mb-3">
                        <h5>Social networks</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2">
                                <a href="https://www.instagram.com/nightspot_app/" target="_blank" className="nav-link p-0 text-body-secondary">
                                    <i className="fa-brands fa-instagram"></i>&nbsp; Instagram
                                </a>
                            </li>
                            <li className="nav-item mb-2">
                                <a href="https://www.facebook.com/nightspotapp" target="_blank" className="nav-link p-0 text-body-secondary">
                                    <i className="fa-brands fa-facebook"></i>&nbsp; Facebook
                                </a>
                            </li>
                            <li className="nav-item mb-2">
                                <a href="https://twitter.com/nightspot_app" target="_blank" className="nav-link p-0 text-body-secondary">
                                    <i className="fa-brands fa-twitter"></i>&nbsp; Twitter
                                </a>
                            </li>
                            <li className="nav-item mb-2">
                                <a href="https://www.tiktok.com/@nightspot_app" target="_blank" className="nav-link p-0 text-body-secondary">
                                    <i className="fa-brands fa-tiktok"></i>&nbsp; Tiktok
                                </a>
                            </li>

                        </ul>
                    </div>


                    <div className="col-md-5 offset-md-1 mb-3">
                        <div className="row align-items-center ">
                            <div className="col-md-6 ">
                                <a href="https://play.google.com/store/apps/details?id=nightspotapp.com" target="_blank">
                                    <img src="/img/social-network/google-play-badge.png"  className="img-fluid" alt="google play download" />
                                </a>
                            </div>
                            <div className="col-md-6">
                                <a href="https://apps.apple.com/app/nightspot-nightclubs-events/id6448525000" target="_blank">
                                    <img src="/img/social-network/app-store-badge.png"  className="img-fluid" alt="AppStore download" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
                    <p>© 2023 Nightspot. All rights reserved.</p>
                    
                </div>
                </div>
            </footer>
  )
}

export default Footer;