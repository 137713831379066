import react from "react";
import Request from "../../api/Requests";

const getInfoDeleteAccount = (params) => {
    return Request('site/get-delete-account-info', 'POST', params);
}

const deleteAccount = (params) => {
    return Request('site/post-delete-account-info', 'POST', params);
}


export default {
    getInfoDeleteAccount,
    deleteAccount
}