import react from "react";
import Request from "../../../api/Requests";

const getInfoSlug = (params, type) => {
    params["type"] = type;
    return Request('guestlist/get-info-slug', 'POST', params);
}

const getInfoEvent = (params, type) => {
    params["type"] = type;
    return Request('guestlist/get-info-event', 'POST', params);
}

const submitEnterGuestlist = (params, type) => {
    console.log(type)
    params["type"] = type;
    return Request('guestlist/enter-guestlist', 'POST', params);
}

export default {
    getInfoSlug,
    getInfoEvent,
    submitEnterGuestlist
}