import react, { useState, useEffect, useContext } from "react";
import Menu from '../Layout/Menu/Menu';
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import './Login.css';
import Request from "../../api/Requests";
import { AuthContext } from "../../Context/AppContext";
import Loading from "../../components/Loading";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoginScreen = () => {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { userInfo, setUserInfo } = useContext(AuthContext);
    const [showAlerDefinirPasswordBussiness, setShowAlerDefinirPasswordBussiness] = useState(false);
    const [form, setForm] = useState({
        email: '',
        password: ''
    });

    const [showPassword, setShowPassword] = useState(false);


    useEffect(() => {
        document.title = 'Login | Nightspot';
        setIsLoading(false)
    }, [])


    useEffect(() => {
        if (userInfo && userInfo.firstName) {
            navigate(`/my/dashboard`);
        }
    }, [userInfo])


    const handleLoginRequest = async (e) => {
        setIsLoading(true)
        e.preventDefault(); // previne o comportamento padrão do formulário (recarregando a página)
        let params = form;
        let response = await Request('site/login', 'POST', params);

        if (response) {
            if (response.status == true) {
                setUserInfo({
                    firstName: response.data.firstname,
                    lastName: response.data.lastname,
                })
                localStorage.setItem('token-api', response.access_token);
                navigate(`/my/dashboard`);
            } else {
                toast.error(response.error, {
                    position: "top-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        }
        setIsLoading(false)
    }

    // handler inputs
    const handleChangeInputs = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    }

    const handlerCheckAppUser = async () => {
        setIsLoading(true)
        let params = form;
        let response = await Request('site/login-check-isuser-app', 'POST', params);
        if (response && response.status == true) {
            if (!response.data.isUserApp) {
                setShowPassword(true)
            } else {
                setShowAlerDefinirPasswordBussiness(true)
            }
        } else {
            toast.error("Não encontramos o seu email registado, faça o registo primeiro.", {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
        setIsLoading(false)
    }


    return (isLoading ? <Loading /> :

        <div className="container-fluid">
            <ToastContainer />
            <div className="row">
                <div className="col-xs-12 col-sm-7 ">
                    <div className="row">
                        <div className="col-12" style={{ marginTop: "15px" }}>
                            <Link to="/"><img className="mb-4" src="/img/logo/logo-icon.svg" alt="" width="72" height="57" /></Link>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 text-center" style={{ marginTop: "15px" }}>
                            <h2>Login</h2>
                        </div>
                    </div>
                    <div className="row" style={{ display: showAlerDefinirPasswordBussiness === false ? 'none' : 'flex' }}>
                        <div className="col-md-7 mx-auto">
                            <div className="alert alert-success" role="alert">
                                Defina uma password para a sua conta bussiness com a ligação que enviámos para o seu e-mail
                            </div>
                        </div>
                    </div>
                    <div className="row"  >
                        <div className="col-md-7 mx-auto">
                            <form onSubmit={handleLoginRequest}>



                                {/* <h1 className="h3 mb-3 fw-normal"></h1> */}
                                <div className="alert alert-success" role="alert" style={{ display: searchParams.get('ref') ? 'block' : 'none' }}>
                                    O seu registo foi efectuado com êxito. Active a sua conta com a ligação que enviámos para o seu e-mail
                                </div>
                                <div style={{ display: showAlerDefinirPasswordBussiness === true ? 'none' : 'block' }}>
                                    <div className="form-floating">
                                        <input type="email" className="form-control form-input" id="floatingInput" placeholder="name@example.com" name="email" value={form.email} onChange={(e) => handleChangeInputs(e)} />
                                        <label htmlFor="floatingInput">Email</label>
                                    </div>
                                    <div>&nbsp;</div>
                                    {
                                        showPassword === true ?
                                            <>
                                                <div className="form-floating">
                                                    <input type="password" className="form-control form-input" id="floatingPassword" placeholder="Password" name="password" autoComplete="current-password" value={form.password} onChange={(e) => handleChangeInputs(e)} />
                                                    <label htmlFor="floatingPassword">Password</label>
                                                </div>
                                                <div>&nbsp;</div>
                                                <button type="submit" className="w-100 btn btn-primary">
                                                    LOGIN
                                                </button>
                                            </>
                                            :
                                            <>
                                                <button onClick={() => handlerCheckAppUser()} className="w-100 btn btn-primary">
                                                    SEGUINTE
                                                </button>
                                            </>
                                    }


                                    <div>&nbsp;</div>
                                    <div className="text-end">
                                        <Link className="link-nostyle" to="/forgot-password">Recuperar password</Link>
                                    </div>
                                </div>
                                <div>&nbsp;</div>
                                <div style={{ position: 'relative' }}>
                                    <hr className="grad-line" />
                                    <div className="centered-text"></div>
                                </div>

                                <div>&nbsp;</div>
                                <div className="text-center">
                                    <span className="text-body-secondary">Não está registado? </span>
                                    <Link className="link-nostyle" to="/signup" style={{ color: "#00deff" }}>Registar</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-5 block-bg-video d-none d-sm-block">
                    <video src="/img/video/login-intro.mp4" autoPlay muted loop></video>
                    <div className="block-caption">
                        <div className="caption">
                            <h1>Step right in and unlock your potential with us.</h1>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default LoginScreen