import React, { createContext, useState, useEffect } from 'react';
import AppRequests from '../AppRequests';
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: ''
  });

    const UserInfo = async () => {
    let info = await AppRequests.userRequest();
    if (info && info.status === true) {
      setUserInfo({
        firstName: info.data.firstname,
        lastName: info.data.lastname,
      })
    }
    
  }
  useEffect(() => {
    UserInfo()
  }, [])
  

  const [showModalUserSettings, setShowModalUserSettings] = useState(false);

  const handleShowModalUserSettings = () => {
    setShowModalUserSettings(true);
  };

  const handleHideModalUserSettings = () => {
    setShowModalUserSettings(false);
  };

  return (
    <AuthContext.Provider value={{ userInfo, setUserInfo, showModalUserSettings, handleShowModalUserSettings, handleHideModalUserSettings }}>
      {children}
    </AuthContext.Provider>
  );
};
