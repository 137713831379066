import React, { useState, useEffect, useContext } from "react";
import { Input, SelectCountries, Select } from '../Layout/Forms/Form';
import { Link, useParams, useNavigate, useSearchParams } from "react-router-dom";

import './SignUp.css';
import SignupRequests from "./SignupRequests";
import { AuthContext } from "../../Context/AppContext";
import ReCAPTCHA from 'react-google-recaptcha';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from "../../components/Loading";

const SignUp = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { userInfo, setUserInfo } = useContext(AuthContext);
    let captcha;
    const setCaptchaRef = (ref) => {
        if (ref) {
            return captcha = ref;
        }
    };
    const [state, setState] = useState({
        form: {
            firstname: "",
            lastname: "",
            phone_country: "",
            phone_number: "",
            email: "",
            password: "",
            password_repeat: "",
            account_type: "",
            recaptcha: ""
        },
        errors: []
    });

    const [isVerified, setIsVerified] = useState(false);

    const handleRecaptchaChange = (token) => {
        setIsVerified(true);
        handleChangeInputs({
            target: {
                name: "recaptcha",
                value: token
            }
        })
    };

    const [countryList, setCountryList] = useState([]);
    const handleChangeInputs = (e) => {

        if (!isLoading) {
            setState({
                ...state,
                form: {
                    ...state.form,
                    [e.target.name]: e.target.value
                }
            });
        }
    }

    const seriaizeCountryList = () => {
        if (countryList && countryList.length > 0)
            return countryList.map((item) => {
                return {
                    label: item.name,
                    value: item.phone_country,
                    icon: item.icon
                }
            });
    }


    useEffect(async () => {
        document.title = 'Sign up | Nightspot';
        setIsLoading(true);
        let info = await SignupRequests.getInfoSignup();
        if (info && info.status) {
            setCountryList(info.data);
            setIsLoading(false);
            setState({
                ...state,
                form: {
                    ...state.form,
                    phone_country: "+351"
                }
            })
        }
    }, [])

    const handlerSubmitFormSignup = async (e) => {
        setIsLoading(true)
        e.preventDefault();

        if(state.form.password !== state.form.password_repeat){
            setState({
                ...state,
                errors: {
                    password_repeat: ["As passwords não correspondem"],
                    password:[""]
                }
            })
            setIsLoading(false)
            return;
        }

        if (isVerified && state.form.recaptcha) {

            let response = await SignupRequests.signUp(state.form);
            if (response && response.status) {
                setUserInfo({
                    firstName: response.data.firstname,
                    lastName: response.data.lastname,
                })
                localStorage.setItem('token-api', response.access_token);

                toast.success("O seu registo foi efectuado com êxito. Active a sua conta com a ligação que enviámos para o seu e-mail", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                navigate('/login?ref=signup');
            } else {
                setState({
                    ...state,
                    errors: response.errors
                })
            }
            captcha.reset();
        } else {
            toast.error("Verifique o reCAPTCHA antes de submeter o formulário.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }

        setIsLoading(false)
    }

    return (isLoading ? <Loading /> :
        <div className="container-fluid">
            <ToastContainer />
            <div className="row">
                <div className="col-xs-12 col-sm-7 block-bg-image-signup d-none d-sm-block">
                    <div className="text-signup">

                    </div>
                    <h1 ><span>Get Started</span></h1>
                    <p>&nbsp;</p>
                    <p>Increase your customer range and obtain valuable insights easily through our marketing and promoter management platform.</p>
                    <p>&nbsp;</p>
                    <p>Register now and take the first step towards <span style={{ fontWeight: 'bold' }}>success</span>!</p>
                </div>
                <div className="col-xs-12 col-sm-5 block-form-signup">
                    <div className="row">
                        <div className="col-12" style={{ marginTop: "15px" }}>
                            <Link to="/" className="link-nostyle" style={{fontSize:"0.9em"}}><i className="fa-solid fa-arrow-left"></i> VOLTAR</Link>
                        </div>
                    </div>
                    <form onSubmit={handlerSubmitFormSignup} >

                        <div className="row">
                            <div className="col-6 ">
                                <Input
                                    label="Nome"
                                    name="firstname"
                                    value={state.form.firstname}
                                    onChange={(e) => handleChangeInputs(e)}
                                    errors={state.errors}
                                />
                            </div>
                            <div className="col-6 ">
                                <Input
                                    label="Apelido"
                                    name="lastname"
                                    value={state.form.lastname}
                                    onChange={(e) => handleChangeInputs(e)}
                                    errors={state.errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 ">
                                <SelectCountries
                                    label="&nbsp;"
                                    name="phone_country"
                                    value={state.form.phone_country}
                                    onChange={(e) => handleChangeInputs(e)}
                                    options={seriaizeCountryList()}
                                    errors={state.errors}
                                />
                            </div>
                            <div className="col-4">
                                <Input
                                    label="Nº Telemóvel"
                                    name="phone_number"
                                    value={state.form.phone_number}
                                    onChange={(e) => handleChangeInputs(e)}
                                    errors={state.errors}
                                />
                            </div>
                            {/* <div className="col-6">
                            <Select
                                label="Account type"
                                name="account_type"
                                value={state.form.account_type}
                                onChangeOption={(e) => handleChangeInputs(e)}
                                options={[
                                    {
                                        label: "Company",
                                        value: "Company"
                                    },
                                    {
                                        label: "Artist",
                                        value: "Artist"
                                    },
                                ]}
                                errors={state.errors}
                            />
                        </div> */}
                        </div>
                        <div className="row">
                            <div className="col-12 ">
                                <Input
                                    label="Email"
                                    name="email"
                                    value={state.form.email}
                                    onChange={(e) => handleChangeInputs(e)}
                                    errors={state.errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Input
                                    label="Password"
                                    name="password"
                                    type="password"
                                    value={state.form.password}
                                    onChange={(e) => handleChangeInputs(e)}
                                    errors={state.errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Input
                                    label="Repita a password"
                                    name="password_repeat"
                                    type="password"
                                    value={state.form.password_repeat}
                                    onChange={(e) => handleChangeInputs(e)}
                                    errors={state.errors}
                                />
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: '30px' }}>
                            <div className="col-12">
                                <ReCAPTCHA ref={(r) => setCaptchaRef(r)} theme="dark" sitekey="6LeVVUYlAAAAAE8OMUoEmZH4DD_7ukaaDB-S6E9H" onChange={handleRecaptchaChange} />

                            </div>
                        </div>
                        <div className="row " style={{ marginTop: '30px' }}>
                            <div className="col-8 d-grid  mx-auto text-center">
                                <button type="submit" className="btn btn-primary" >REGISTAR</button>
                            </div>
                        </div>
                    </form>
                    <div className="row" style={{ marginTop: '10px' }}>
                        <div className="col-8 d-grid mx-auto text-center">
                            <div style={{ position: 'relative' }}>
                                <hr className="grad-line" />
                                <div className="centered-text"></div>
                            </div>
                            <Link className="btn btn-info" to="/login" >LOGIN</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default SignUp;