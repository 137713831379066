import react from "react";
import Menu from '../Layout/Menu/Menu';
import Footer from '../Layout/Footer/Footer';

import './Home.css';

const HomeScreen = () => {
    return (
        <div>
            <Menu />
            <div className="home-banner">
                <div className="caption">
                    <p>Check the best</p>
                    <p><span>NIGHTSPOT</span>,</p>
                    <p>in your area</p>
                </div>

            </div>


            <div className="container-fluid" id="section-features">
                <div className="row mt-md-5 mb-md-5 mb-sm-5">
                    <div className="col-md-4 d-flex ">
                        <img src="/img/banners/banner_mockup.png" alt="Home Banner" className="img-fluid" />
                    </div>
                    <div className="col-md-8 ps-md-5 mt-5">
                        <h2>Funcionalidades</h2>
                        <p className="fs-lg text-muted text-center text-md-start mb-4 mb-xl-5"></p>

                        <div className="row row-cols-1 row-cols-sm-2 pt-2 pt-sm-3 pt-xl-2">


                            <div className="col pb-2 pb-xl-0 mb-4 mb-xl-5">
                                <div className="d-flex align-items-start pe-xl-3">
                                    <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                                        <span className="fa-stack fa-2x">
                                            <i className="fa-solid fa-ban fa-stack-2x"></i>
                                            <i className="fa fa-coins fa-stack-1x"></i>
                                        </span>
                                    </div>
                                    <div className="ps-4 ps-sm-3 ps-md-4">
                                        <h3 className="h5 pb-1 mb-2">GRÁTIS</h3>
                                        <p className="mb-0"  style={{textAlign: 'justify'}}>A utilização da aplicação é gratuita.</p>
                                    </div>
                                </div>
                            </div>


                            <div className="col pb-2 pb-xl-0 mb-4 mb-xl-5">
                                <div className="d-flex align-items-start ps-xl-3">
                                    <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                                        <span className="fa-stack fa-2x">
                                            <i className="fa-solid fa-champagne-glasses fa-stack-2x"></i>
                                        </span>
                                    </div>
                                    <div className="ps-4 ps-sm-3 ps-md-4">
                                        <h3 className="h5 pb-1 mb-2">Lista de eventos</h3>
                                        <p className="mb-0" style={{textAlign: 'justify'}}>Mantenha-se atualizado sobre as festas mais populares num só lugar. </p>
                                    </div>
                                </div>
                            </div>


                            <div className="col pb-2 pb-xl-0 mb-4 mb-xl-5">
                                <div className="d-flex align-items-start pe-xl-3">
                                    <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">

                                        <span className="fa-stack fa-2x">
                                            <i className="fa-solid fa-house-flag fa-stack-2x"></i>
                                        </span>
                                    </div>
                                    <div className="ps-4 ps-sm-3 ps-md-4">
                                        <h3 className="h5 pb-1 mb-2">Lista de Spots</h3>
                                        <p className="mb-0"  style={{textAlign: 'justify'}}>Planeie a sua noite com facilidade, acedendo a informações importantes como o horário de funcionamento, regras de vestuário e possibilidades de reserva vip.</p>
                                    </div>
                                </div>
                            </div>


                            <div className="col pb-2 pb-xl-0 mb-4 mb-xl-5">
                                <div className="d-flex align-items-start ps-xl-3">
                                    <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                                        <span className="fa-stack fa-2x">
                                            <i className="fa-solid fa-qrcode fa-stack-2x"></i>
                                        </span>
                                    </div>
                                    <div className="ps-4 ps-sm-3 ps-md-4">
                                        <h3 className="h5 pb-1 mb-2">Guestlist</h3>
                                        <p className="mb-0"  style={{textAlign: 'justify'}}>Maximize a sua noite garantindo o seu lugar na guestlist</p>
                                    </div>
                                </div>
                            </div>


                            <div className="col pb-2 pb-xl-0 mb-4 mb-xl-5">
                                <div className="d-flex align-items-start pe-xl-3">
                                    <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                                        <span className="fa-stack fa-2x">
                                            <i className="fa-solid fa-user-tag fa-stack-2x"></i>
                                        </span>
                                    </div>
                                    <div className="ps-4 ps-sm-3 ps-md-4">
                                        <h3 className="h5 pb-1 mb-2">Promotores</h3>
                                        <p className="mb-0"  style={{textAlign: 'justify'}}>Com a nossa funcionalidade Promotores, terá acesso a listas de convidados exclusivas, entradas VIP, descontos em bebidas e outros incentivos interessantes. </p>
                                    </div>
                                </div>
                            </div>


                            <div className="col pb-2 pb-xl-0 mb-4 mb-xl-5">
                                <div className="d-flex align-items-start ps-xl-3">
                                    <div className="d-table bg-secondary rounded-3 flex-shrink-0 p-3 mb-3">
                                        <span className="fa-stack fa-2x">
                                            <i className="fa-solid fa-ticket fa-stack-2x"></i>
                                        </span>
                                    </div>
                                    <div className="ps-4 ps-sm-3 ps-md-4">
                                        <h3 className="h5 pb-1 mb-2">Bilhetes</h3>
                                        <p className="mb-0"  style={{textAlign: 'justify'}}>A funcionalidade de emissão de bilhetes da nossa aplicação oferece uma forma descomplicada de comprar bilhetes para as festas, concertos e noites de discoteca mais populares.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <Footer />
        </div>
    )
}

export default HomeScreen;