import { useEffect } from "react";
import TablesRequests from "./TablesRequests";
import { useNavigate, Link, useParams } from "react-router-dom";

const TableTransactionRedirect  = () => {
    const urlParams = useParams();
    const navigate = useNavigate();
    useEffect(async() => {
        let response = await TablesRequests.getUrlCore(urlParams);
        if (response && response.status == true) {
            navigate(response.data);
        }
    }, [])
    return (
        <>Estás a ser redirectionado...</>
    )
}

export default TableTransactionRedirect;