import react from "react";
import Request from "../../api/Requests";

const getInfoSignup = () => {
    return Request('site/signup-info', 'GET');
}


const signUp = (params) => {
    return Request('site/sign-up', 'POST', params);
}


const activateSignUp = (params) => {
    return Request('site/sign-up-activate', 'POST', params);
}




export default {
    getInfoSignup,
    signUp,
    activateSignUp
}