import React from "react";
import Menu from "../../Layout/Menu/Menu";
import Footer from "../../Layout/Footer/Footer";

const PrivacyScreen = () => {
    return (
        <>
            <Menu />

            <div className="pt-5"></div>
            <div className="container pt-5" >
                <div className="row">
                    <div className="col-12" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'center', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Termos e Condições APP Nightspot</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Os presentes Termos e Condições destinam-se a regular as condições gerais de uso e informação legal pelos quais a aplicação de telemóvel Nightspot (doravante APP Nightspot) da Forever Friday, Lda (doravante Nightspot), pessoa coletiva com NIF 517 654 377, com sede em Rua Terraços de Vale de Lobos, N.º 49, 1.º Esq., Barreira, 2410-491 Leiria, se iram reger.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Através destes Termos e Condições, o Nightspot põe à disposição dos utilizadores a APP Nightspot.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>A utilização da APP Nightspot implica a aceitação e concordância plena de todos os parâmetros presentes nos Termos e Condições (doravante T&amp;C) descritos, assim como da Política de Privacidade em vigor quando à APP Nightspot.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Caso não aceite, parcial ou integralmente, qualquer das condições estipuladas não deverá utilizar a APP Nightspot. Se aceder às mesmas, estará a comprometer-se e responsabilizar-se a respeitar as condições determinadas na presente página e na Política de Privacidade.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>O Nightspot reserva o direito de atualizar e alterar os T&amp;C sem aviso prévio. É aconselhável verificar periodicamente os T&amp;C para averiguar qualquer alteração ou atualização.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Terminologia</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>A terminologia seguinte aplica-se as estes T&amp;C, assim como a todo e qualquer acordo:</span></p>
                        <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'italic', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Utilizador</span><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;refere-se a si, enquanto pessoa que irá usufruir da APP Nightspot e que está a aceder a este site para aceitar os termos e condições da Empresa.</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>A</span><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'italic', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;Empresa</span><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>,&nbsp;</span><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'italic', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Nós</span><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;e&nbsp;</span><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'italic', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Nosso</span><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>, referem-se ao Nightspot.</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'italic', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Serviço</span><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;refere-se a todas as funcionalidades disponíveis para o utilizador, através da APP Nightspot.</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'italic', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Estabelecimentos Noturnos</span><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;refere-se a todos os espaços, bares e discotecas ou outro tipo de estabelecimentos noturnos, listados na APP Nightspot.</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'italic', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Conteúdo</span><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>, refere-se às imagens, fotografias, áudio, vídeos, dados de localização, locais próximos, datas, gostos, avaliações, informações de perfil, entre outros.</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'italic', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Conteúdo Nightspot</span><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;refere-se aos conteúdos que a empresa cria e disponibiliza em associação ao serviço prestado que inclui todas as funcionalidades e interfaces, gráficos e relatórios de análise, design, códigos informáticos, software e outros dados.</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'italic', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Conteúdos de Terceiros</span><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;refere-se a conteúdos disponíveis através do serviço com origem que não o Nightspot ou os seus utilizadores.</span></p>
                            </li>
                        </ul>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Qualquer uso da terminologia acima ou outras palavras no singular, plural, em maiúscula/minúscula e/ou ele/ela ou eles/elas são tomados como intermutáveis e, portanto, referem-se ao mesmo.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Propriedade Intelectual</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>O conteúdo, domínio, marca, logótipos, informações presentes e disponibilizadas na APP Nightspot são propriedade do Nightspot ou foram concedidos a esta, encontrando-se assim protegidos pelos direitos de autor e direitos conexos. Toda e qualquer utilização, reprodução, cópia ou divulgação de conteúdos visuais, audiovisuais ou escritos, está sujeita a aprovação prévia por parte do Nightspot.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>O utilizador compromete-se a respeitar integralmente as condições e direitos referidos, bem como a abster-se de atos que possam ir contra a lei e direitos como direitos de autor, direitos conexos, entre outras legislações aplicáveis à utilização de imagens, vídeos, conteúdos, etc sem aprovação expressa do Nightspot. A empresa não poderá ser responsabilizada pela eventual utilização indevida de tais produtos.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>O utilizador poderá utilizar a informação disponibilizada pelas plataformas Nightspot apenas para fins pessoais e não-comerciais.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Qualquer violação pelo utilizador poderá resultar na suspensão imediata do direito a utilizar as plataformas Nightspot, bem como em potenciais responsabilidades civis e criminais por infração dos direitos de autor ou de outros direitos.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Condições de Acesso</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Para poder ser utilizador ou consumidor das plataformas Nightspot, têm de ser cumpridos os seguintes requisitos:</span></p>
                        <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Ser maior de 16 anos de idade;</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Preencher de maneira verdadeira os campos obrigatórios do formulário de registo, no qual se solicitam dados de carácter pessoal;</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Aceitar os presentes Termos e Condições;</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Aceitar a Política de Privacidade e Proteção de Dados.</span></p>
                            </li>
                        </ul>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>O utilizador garante que todos os dados fornecidos sobre a sua identidade e legitimidade são verdadeiros, corretos e completos, comprometendo-se a manter os seus dados atualizados.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Caso o utilizador comunique qualquer dado falso, incorreto ou incompleto, ou caso a empresa considere existirem motivos fundados para duvidar da veracidade, exatidão e integridade dos mesmos, poderá se negado o acesso e uso, presente ou futuro, das plataformas, bem como qualquer conteúdo das mesmas.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Ao inscrever-se na APP Nightspot, o utilizador terá de fornecer o número de telemóvel, que será depois conferido através de um código único enviado por SMS. O utilizador será responsável pela não divulgação dos seus dados de conta, bem como o único responsável em caso de uso destes dados por terceiros.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Informações Recolhidas</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>A APP Nightspot recolhe as seguintes informações dos utilizadores:</span></p>
                        <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Número de telefone</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Indicativo do país</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Primeiro e último nome</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Endereço de e-mail</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Data de nascimento</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Gênero</span></p>
                            </li>
                        </ul>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>A APP Nightspot também pode recolher dados de localização GPS se autorizado pelo utilizador. Ao usar o recurso de localização da aplicação, o utilizador concede permissão para a recolha e processamento de dados de localização. O utilizador pode desativar essa função a qualquer momento nas configurações do dispositivo.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>As informações recolhidas são utilizadas para fornecer e melhorar os serviços APP Nightspot. Podem ser utilizadas para:</span></p>
                        <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Personalizar a experiência do usuário;</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Enviar notificações relevantes;</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Analisar o uso da aplicação para melhorias;</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Cumprir requisitos legais.</span></p>
                            </li>
                        </ul>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>As informações pessoais dos utilizadores não serão partilhadas com terceiros, exceto quando necessário para fornecer os serviços da aplicação, como a inscrição na guest list para fins estatísticos, ou quando exigido por lei.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Aviso de Responsabilidade</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Ao criar e usar a conta na APP Nightspot, o utilizador é responsável pela manutenção da conta, confidencialidade e por toda a atividade proveniente da sua conta. Utilizando as suas funcionalidades, o utilizador aceita expressa e inequivocamente os T&amp;C na sua versão mais atualizada.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>O Nightspot não tem a obrigação de verificar a identidade dos utilizadores, nem a autenticidade dos dados que os mesmos proporcionam.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>O Nightspot não é responsável por eventuais informações divulgadas por terceiros, falhas técnicas ou quaisquer outros problemas que impeçam o acesso e bom funcionamento das suas plataformas.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>O uso de quaisquer informações pessoais que o utilizador nos forneça durante o processo de criação de conta é regulado pela Política de Privacidade e Proteção de Dados Pessoais.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Proteção de Dados Pessoais</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>O Nightspot compromete-se a proteger a privacidade e a segurança dos dados pessoais dos utilizadores. Utilizamos medidas de segurança adequadas para proteger essas informações contra acesso não autorizado ou divulgação.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Encerramento da Conta</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>O utilizador pode encerrar a sua conta a qualquer momento. Reservamo-nos o direito de encerrar contas que violem estes T&amp;C.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Publicidade</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Ao criar uma conta, como utilizador, está a optar por receber comunicações via e-mail, via SMS ou via&nbsp;</span><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'italic', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>push notifications</span><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;do Nightspot. Poderá gerir as suas preferências de comunicação na sua conta, mas não será possível deixar de receber comunicações da empresa com informações legais, políticas de serviço ou administrativos.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>A APP Nightspot irá apresentar anúncios e promoções, que estarão direcionados ao tipo de conteúdo presente nos serviços prestados.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>O Nightspot não se responsabiliza por qualquer erro, omissão ou imprecisão no material promocional.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Garantias</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>O Nightspot não se responsabiliza por quaisquer erros, enganos, imprecisões ou omissões nos conteúdos, quaisquer acessos não autorizados ao uso dos serviços prestados, quaisquer bugs e vírus que possam ser transmitidos para ou através dos serviços prestados através das ações de terceiros, quaisquer perdas e danos de qualquer tipo incorridos como resultado do uso de qualquer conteúdo publicado, enviado, transmitido ou disponibilizado de qualquer forma através dos serviços prestados.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Qualquer material descarregado ou obtido através dos serviços prestados é efetuado por conta e risco do utilizador, que será o único responsável por qualquer dano ao seu sistema informático ou a qualquer outro equipamento ou perda de dados que resulte do download de tais materiais.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Reclamações</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>O cliente poderá apresentar uma reclamação relativa a qualquer questão, ato, informação ou serviço prestado referentes ao Nightspot através do contacto de e-mail info@nightspot.pt ou através do Livro de Reclamações eletrónico disponível pelo link:</span><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', color: '#ff0000', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>
                            <a href="https://www.livroreclamacoes.pt/Inicio/" target="_blank">www.livroreclamacoes.pt/Inicio/</a>
                        </span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Remoção de Conteúdos</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>O Nightspot reserva-se no direito de, a qualquer momento e sem notificação prévia, remover, bloquear ou impedir o acesso a quaisquer conteúdos que considere violar os T&amp;C ou que sejam prejudiciais para os serviços prestados ou para os nossos utilizadores, bem como retirar ou suspender qualquer mensagem com conteúdo ilegal ou ofensivo.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Atualizações de Software</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>A empresa poderá incluir a opção de automaticamente verificar se há atualizações ao software, podendo os utilizadores serem notificados da existência de tais atualizações ou melhorias bem como serem transferidas automaticamente para o seu dispositivo. Poderão também ser necessárias atualizações ou melhorias ao software para que seja permitido utilizar os serviços.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'center', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>&nbsp;</span><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>PROCESSAMENTO DE DADOS PESSOAIS</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Por favor, verifique a Política de Privacidade e a Política de Cookies.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'center', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>LEI APLICÁVEL – JURISDIÇÃO</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Este contrato está sujeito à Lei portuguesa e legislação em vigor</span></p>

                        <div style={{marginTop:'25px'}}>&nbsp;</div>
                        <hr  />
                        <div style={{marginTop:'25px'}}>&nbsp;</div>

                        {/* POLITICA DE PRIVACIDADE */}


                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'center', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Política de Privacidade e Proteção de Dados APP Nightspot</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Introdução</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>A presente Política de Privacidade e Proteção de Dados destina-se a explicar como o Nightspot recolhe, armazena e trata os seus dados pessoais e outros que possam ser recolhidos para fins de análise estatística.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>A Forever Friday, Lda (doravante Nightspot) com sede na Rua Terraços de Vale de Lobos, N.º 49, 1.º Esq., Barreira, 2410-491 Leiria, NIF 517 654 377, responsável pela aplicação Nightspot (doravante APP Nightspot), compromete-se a garantir a privacidade e a proteção dos dados pessoais dos utilizadores, seguindo todas as normas legais e regulamentares em vigor.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Princípios Orientadores</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>O objetivo principal desta política é consolidar a relação de confiança e proximidade entre o Nightspot e respetivos utilizadores e consumidores. A mesma tem também como objetivo demonstrar a transparência no tratamento dos dados recolhidos e informar todos os utilizadores dos seus direitos e da forma como podem exercê-los, enquanto titulares de dados pessoais.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Base Legal</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>O Nightspot atua no estrito cumprimento dos princípios descritos nesta política, do Regulamento (UE) 2016/679 (Regulamento Geral sobre a Proteção de Dados – RGPD) e da legislação de proteção de dados aplicável, em todos os processos que envolvam o tratamento de dados pessoais.&nbsp;</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Segundo o Acordo com o RGPD, por dados pessoais define-se: “Qualquer informação relativa a uma pessoa singular identificada ou identificável (”titular da informação“); uma pessoa singular identificável é aquela que pode ser identificado, direta ou indiretamente, em particular por referência a um identificador, como um nome, um número de identificação, dados de localização, um identificador online ou a um ou mais fatores específicos do físico, fisiológico, identidade genética, mental, económica, cultural ou social dessa pessoa natural.”</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Informações Recolhidas</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>A APP Nightspot recolhe as seguintes informações dos utilizadores:</span></p>
                        <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Número de telefone</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Indicativo do país</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Primeiro e último nome</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Endereço de e-mail</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Data de nascimento</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Gênero</span></p>
                            </li>
                        </ul>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>A aplicação também pode recolher dados de localização GPS se autorizado pelo utilizador.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Uso de Informações</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>As informações recolhidas são utilizadas para fornecer e melhorar os serviços da APP Nightspot, incluindo:</span></p>
                        <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Personalização da experiência do usuário</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Envio de notificações relevantes</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '0pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Análise do uso do aplicativo para melhorias</span></p>
                            </li>
                            <li dir="ltr" style={{ listStyleType: 'disc', fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre' }} aria-level={1}>
                                <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Cumprimento de requisitos legais</span></p>
                            </li>
                        </ul>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Consentimento para Recolha de Localização</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Ao utilizar o recurso de localização da APP Nightspot, o utilizador concede permissão para a recolha e processamento de dados de localização. Esse consentimento pode ser revogado a qualquer momento nas configurações do dispositivo.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Proteção de Dados</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Utilizamos várias medidas que visam garantir a segurança da informação recolhida, a proteção dos dados pessoais recolhidos, impedir a utilização, alteração ou acesso indevido, a divulgação, a perda ou destruição não autorizadas.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Como titular dos dados, o utilizador tem direito a saber quais os dados recolhidos e armazenados, assim como a alterar os mesmos na área do perfil e solicitar à empresa a sua exportação ou eliminação.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Partilha de Dados</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>As informações pessoais dos utilizadores não serão partilhadas com terceiros, exceto quando necessário para fornecer os serviços da aplicação, como a inscrição na guest list para fins estatísticos, ou quando exigido por lei.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Direitos do Utilizador</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Os utilizadores têm o direito de aceder, corrigir, excluir ou limitar o processamento das suas informações pessoais. Para exercer esses direitos, basta entrar em contato connosco através do email info@nightspot.pt.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Atualizações na Política de Privacidade</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>O Nightspot reserva-se no direito de atualizar e alterar periodicamente a sua Política de Privacidade e Proteção de Dados, de forma a refletir qualquer modificação na forma como os dados pessoais dos nossos utilizadores são processados ou em termos legais. No Nightspot, será exibida a versão mais recente e atual da Política de Privacidade e Proteção de Dados, sendo aconselhável que a verifique periodicamente, para averiguar qualquer atualização ou alteração.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Menores de Idade</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>A APP Nightspot não é destinada a menores de 16 anos. Se não cumprir com este requisito, por favor, não use a aplicação.</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Contacto</span></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Para perguntas relacionadas com esta Política de Privacidade, entre em contato connosco através do email info@nightspot.pt.</span></p>
                        <p><br /></p>
                        <p dir="ltr" style={{ lineHeight: '1.3800000000000001', textAlign: 'justify', marginTop: '0pt', marginBottom: '8pt' }}><span style={{ fontSize: '11pt', fontFamily: 'Arial,sans-serif',  backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>Ao utilizar a APP Nightspot, o utilizador concorda com esta política de privacidade.</span></p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default PrivacyScreen;