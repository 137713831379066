import react from "react";
import Request from "../../../api/Requests";

const getInfoEventTable = (params, type) => {
    params["type"] = type;
    return Request('guestlist/get-info-event-table', 'POST', params);
}

const submitReserveTable = (params, type) => {
    params["type"] = type;
    return Request('guestlist/reserve-event-table', 'POST', params);
}

const getInfoEventTransaction = (params, type) => {
    params["type"] = type;
    return Request('guestlist/get-info-event-table-transaction', 'POST', params);
}

const getUrlCore = (params) => {
    return Request('guestlist/get-url-core', 'POST', params);
}

export default {
    getInfoEventTable,
    submitReserveTable,
    getInfoEventTransaction,
    getUrlCore
}