import React, { useState, useEffect } from "react";


const GuestlistAccessEventSuccess = () => {
    return (
        <div className="container-fluid guestlist-success">
            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12" style={{ marginTop: "15px", textAlign: 'center' }}>
                            <img className="mb-4" src="/img/logo/logo.svg" alt="" style={{ height: '10vh' }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 text-center">
                    <h3>Registo na guest list efetuado com sucesso!</h3>
                </div>
            </div>
            {/* <div className="row mt-3">
                <div className="col-sm-12 fs-6" style={{ fontSize: '0.9rem' }}>
                    Prepara já a tua entrada na guestlist fazendo o download da nossa aplicação onde irá estar o teu qrcode de acesso à guestlist
                </div>
            </div> */}
            <div className="row">
                <div className="col-12">
                    <div className="steps mt-5">
                        <div className='step'>
                            <div className='number completed'>
                                <svg viewBox="0 0 512 512" width="100" title="check">
                                    <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
                                </svg>
                            </div>
                            <div className='info mb-3'>
                                <p className='title'>
                                    Registo na guest list
                                </p>
                                <p className='text'>O teu primeiro passo foi concluído com sucesso, confirmamos o teu registo nesta guest list.</p>
                            </div>
                        </div>
                        <div className='step2'>
                            <div className='number'>2</div>
                            <div className='info'>
                                <p className='title'>Download da App Nightspot</p>
                                <p className='text'>Se ainda não tens a aplicação do Nightspot instalada no teu smartphone, faz o download para teres acesso ao teu QR Code exclusivo de acesso à guest list</p>
                            </div>
                        </div>
                        <div className='step'>
                            <div className='number'>3</div>
                            <div className='info'>
                                <p className='title'>QR Code</p>
                                <p className='text'>Na aplicação do Nightspot terás acesso ao teu QR Code exclusivo que te dará o acesso à guest list, basta apresentares o teu QR Code na entrada do evento!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-6 ">
                    <a href="https://play.google.com/store/apps/details?id=nightspotapp.com" target="_blank">
                        <img src="/img/social-network/google-play-badge.png" className="img-fluid" alt="google play download" />
                    </a>
                </div>
                <div className="col-6">
                    <a href="https://apps.apple.com/app/nightspot-nightclubs-events/id6448525000" target="_blank">
                        <img src="/img/social-network/app-store-badge.png" className="img-fluid" alt="AppStore download" />
                    </a>

                </div>
            </div>
        </div>
    )
}

export default GuestlistAccessEventSuccess;