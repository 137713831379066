import React, { useState, useEffect } from "react";
import { Input } from '../Layout/Forms/Form';
import { useNavigate, Link, useParams } from "react-router-dom";

import ForgotPasswordRequests from './ForgotPasswordRequests';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PasswordReset = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const urlParams = useParams();

    const [state, setState] = useState({
        form: {
            password: "",
            password_2: "",
        },
        errors: []
    });
    const handleChangeInputs = (e) => {
        if (!isLoading) {
            setState({
                ...state,
                form: {
                    ...state.form,
                    [e.target.name]: e.target.value
                }
            });
        }
    }

    useEffect(async () => {
        console.log(urlParams)
        let response = await ForgotPasswordRequests.forgotPasswordValidateToken(urlParams);
        if (response && response.status == true) {
            setState({
                ...state,
                form: {
                    ...state.form,
                    token: urlParams.token
                }
            });
        } else {
            navigate(`/`);
        }
    }, [])


    const handleResetPassword = async (e) => {
        e.preventDefault(); // previne o comportamento padrão do formulário (recarregando a página)
        let params = state.form;
        let response = await ForgotPasswordRequests.resetPassword(params);
        if (response && response.status == true) {
            navigate(`/login`);
        } else {
            setState({
                ...state,
                errors: response.errors
            })
        }
    }

    return (
        <div className="container-fluid">
            <ToastContainer />
            <div className="row">
                <div className="col-md-7 block-form-forgot-password">
                    <div className="row">
                        <div className="col-12" style={{ marginTop: "15px" }}>
                            <img className="mb-4" src="/img/logo/logo-icon.svg" alt="" width="72" height="57" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center" style={{ marginTop: "15px" }}>
                            <h1>Escolha a sua nova password!</h1>
                        </div>
                    </div>
                    <div className="row mx-auto">
                        <div className="col-md-7  mx-auto">
                            <form onSubmit={handleResetPassword}>
                                <div className="row" style={{ marginTop: "30px" }}>
                                    <div className="col-12">
                                        <Input
                                            label="Nova Password"
                                            name="password"
                                            type="password"
                                            value={state.form.password}
                                            onChange={(e) => handleChangeInputs(e)}
                                            errors={state.errors}
                                        />
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: "30px" }}>
                                    <div className="col-12">
                                        <Input
                                            label="Repita a Password"
                                            name="password_2"
                                            type="password"
                                            value={state.form.password_2}
                                            onChange={(e) => handleChangeInputs(e)}
                                            errors={state.errors}
                                        />
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: "30px" }}>
                                    <div className="col-12">
                                        <button type="submit" className="btn btn-primary">
                                            Submeter a nova password
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-md-5 block-bg-image-forgot-password">
                    <h2>Sober up your security and pick a password that even a drunk you can remember!</h2>
                </div>
            </div>
        </div >
    )
}

export default PasswordReset;